<template>
  <v-card flat>
    <v-card-title class="pa-0">
      <v-tabs
        background-color="grey lighten-4"
        :disabled="loading"
        mandatory
        slider-color="primary"
        v-model="tab"
        height="56"
      >
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#reminders">
          <v-badge color="green" :value="reminders.length" :content="reminders.length">
            <v-icon size="18" left>mdi-bell-ring</v-icon>
            {{ $t("dashboard.reminder_tab_title") }}
          </v-badge>
        </v-tab>
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#messages">
          <v-badge color="green" :value="dashboardData.messages.length" :content="dashboardData.messages.length">
            <v-icon size="18" left>mdi-chat-processing</v-icon>
            {{ $t(`dashboard.message_tab_title`) }}
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-tabs-items v-model="tab" mandatory :disabled="loading">
        <v-tab-item value="reminders">
          <template v-if="loading">
            <v-card flat height="346">
              <v-card-text class="fill-height pa-0">
                <v-container fill-height justify-center align-center>
                  <v-layout justify-center align-center>
                    <v-progress-circular indeterminate size="60" color="grey lighten-3" />
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="reminders.length"
              class="request-table"
              :items="reminders"
              :loading="loading"
              :no-data-text="loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td width="2%">
                    <employee-profile :employee="toEmployee(row.item)" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{ row.item.branch || "-" }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{ row.item.unit || "-" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td>
                    <v-icon>
                      {{ row.item.type === "BIRTHDATE" ? "mdi-cake-variant" : "mdi-balloon" }}
                    </v-icon>
                    <span v-if="row.item.type === 'BIRTHDATE'">
                      {{ $t("global.birth_date") }}
                    </span>
                    <span v-else-if="row.item.type === 'HIRING'">
                      {{ getHiring(row.item) }}
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card flat height="346">
                <v-card-text class="fill-height pa-0">
                  <v-container fill-height justify-center align-center>
                    <v-layout justify-center align-center>
                      {{ $t("dashboard.messages.no_data_reminders") }}
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>
          </template>
        </v-tab-item>
        <v-tab-item value="messages">
          <template v-if="loading">
            <v-card flat height="346">
              <v-card-text class="fill-height pa-0">
                <v-container fill-height justify-center align-center>
                  <v-layout justify-center align-center>
                    <v-progress-circular indeterminate size="60" color="grey lighten-3" />
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="dashboardData.messages.length"
              class="request-table"
              :items="dashboardData.messages"
              :loading="loading"
              :no-data-text="loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td width="2%">
                    <employee-profile :employee="row.item.sender" />
                  </td>

                  <td>
                    <v-list-item-content>
                      <v-list-item-title>{{ row.item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </td>
                  <td>
                    {{ row.item.timestamp | formattedDateTime }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card flat height="346">
                <v-card-text class="fill-height pa-0">
                  <v-container fill-height justify-center align-center>
                    <v-layout justify-center align-center>
                      {{ $t("dashboard.messages.no_data_messages") }}
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "reminders",
    props: {
      dashboardData: {
        type: Object,
        required: true
      },
      loading: Boolean
    },
    data: vm => ({
      tab: "reminders"
    }),
    computed: {
      reminders() {
        return [...this.birthDates, ...this.hiringDates];
      },
      birthDates() {
        return this.dashboardData.birthDates.map(item => {
          return {
            ...item,
            type: "BIRTHDATE"
          };
        });
      },
      hiringDates() {
        return this.dashboardData.hiringDates.map(item => {
          return {
            ...item,
            type: "HIRING"
          };
        });
      }
    },
    methods: {
      toEmployee(item) {
        return {
          firstName: item.firstName,
          lastName: item.lastName,
          profile: {
            imageUrl: item.imageUrl
          }
        };
      },
      getHiring(item) {
        const hiringDate = this.$moment(item.date);
        const now = this.$moment();
        return now.diff(hiringDate, "years") + `. ${this.$t("date.year").toLocaleLowerCase()}`;
      }
    }
  };
</script>

<style scoped lang="scss">
  .request-table::v-deep {
    .v-data-table__wrapper {
      min-height: 320px;
    }
  }
</style>
